<template>
  <b-modal
    visible
    scrollable
    centered
    size="md"
    :title="'Восстановить специализацию?'"
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <div
      class="d-flex justify-content-center"
    >
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        :disabled="isLoading"
        @click="onSave"
      >
        Восстановить
      </b-button>

      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="float-right ml-2"
        :disabled="isLoading"
        @click="onClose"
      >
        Отменить
      </b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'SpecializationReestablishModal',
  components: {
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
    };
  },
  methods: {
    onClose() {
      this.$emit('input', false);
    },
    async onSave() {
      try {
        this.$emit('restore');
      } finally {
        this.onClose();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
